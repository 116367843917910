.Home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #000814;
  gap: 10px;
}

.intro {
  height: 50vh;
  display: flex;
  padding: 10px;
  margin-top: 20px;
  align-items: center;
  gap: 50px;
}

.intro-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.name-intro {
  font-size: 30px;
  font-weight: 700;
}

.highlight {
  color: #ffc300;
}

.info-intro {
  font-size: 40px;
  font-weight: 700;
}

.learn-more-container {
  display: flex;
  margin-top: 30px;
}

.learn-more {
  padding: 12px 16px;
  border-radius: 50px;
  border: none;
  color: #000814;
  background-color: #ffd60a;
  font-weight: 700;
  font-size: 18px;
  transition: background-color 1s, color 1s;
}

.learn-more:hover {
  background-color: #003566;
  color: #ffd60a;
}

.user-logo {
  height: 300px;
}

.skills {
  width: 1350px;
  height: 35vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5px;
  background-color: #003566;
  margin-bottom: 20px;
}

.skills-title {
  font-size: 60px;
  color: white;
}

.tech-icon-container {
  display: flex;
  max-width: 600px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.tech-icon {
  height: 72px;
}

.tech-icon.react-router {
  height: 72px;
  width: 72px;
}

.web-dev-intro-container {
  color: #001d3d;
  height: 30vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1350px;
  margin-top: 10px;
  margin-bottom: 20px;
  gap: 15px;
}

.web-dev-intro-title {
  font-size: 60px;
}

.web-dev-intro-content {
  font-size: 30px;
}

.github-chart-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 30px;
  max-width: 1350px;
  margin-bottom: 80px;
}

.github-chart-title {
  font-size: 60px;
}

.github-chart {
  width: 1350px;
}

@media (max-width: 480px) {
  .intro {
    flex-direction: column;
    height: 65vh;
    align-content: center;
    justify-content: center;
    padding: 20px;
    padding-top: 65px;
    gap: 50px;
  }

  .intro-text {
    display: flex;
    flex-direction: column;
  }

  .name-intro {
    font-size: 24px;
  }

  .info-intro {
    font-size: 24px;
  }

  .learn-more-container {
    justify-content: center;
  }

  .learn-more {
    padding: 10px 14px;
    font-size: 15px;
  }

  .user-logo {
    height: 200px;
    padding-left: 20px;
  }

  .skills {
    width: 95vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #003566;
  }

  .skills-title {
    text-align: center;
    font-size: 30px;
    color: white;
  }

  .tech-icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .tech-icon {
    height: 40px;
  }

  .tech-icon.react-router {
    height: 40px;
    width: 40px;
  }

  .web-dev-intro-container {
    height: 30vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 95%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .web-dev-intro-title {
    font-size: 32px;
  }

  .web-dev-intro-content {
    font-size: 18px;
  }

  .github-chart-container {
    margin-bottom: 30px;
  }

  .github-chart {
    width: 95vw;
  }

  .github-chart-title {
    font-size: 32px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .intro {
    height: 60vh;
    padding: 20px;
    gap: 50px;
  }

  .name-intro {
    font-size: 20px;
  }

  .info-intro {
    font-size: 24px;
  }

  .learn-more {
    padding: 8px 12px;
    font-size: 14px;
  }

  .user-logo {
    height: 200px;
  }

  .skills {
    width: 95vw;
    height: 30vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    background-color: #003566;
  }

  .skills-title {
    font-size: 32px;
    color: white;
  }

  .github-chart {
    width: 95vw;
  }

  .github-chart-title {
    font-size: 32px;
  }

  .tech-icon-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .tech-icon {
    height: 32px;
  }

  .tech-icon.react-router {
    height: 32px;
    width: 32px;
  }

  .web-dev-intro-container {
    height: 30vh;
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 95%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .web-dev-intro-title {
    font-size: 32px;
  }

  .web-dev-intro-content {
    font-size: 18px;
  }
}
