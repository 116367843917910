.nav {
  position: fixed;
  width: 100vw;
  top: 0;
  padding: 10px;
  padding-left: 0;
  padding-right: 0;
  display: flex;
  justify-content: space-around;
  background-color: #003566;
}

.nav-links {
  display: flex;
  gap: 50px;
}

.home-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  gap: 20px;
}

.L-logo {
  height: 30px;
}

.nav-link {
  font-size: 18px;
  text-decoration: none;
  color: white;
  font-weight: 700;
}

.nav-link.active {
  border-bottom: 2px solid #ffd60a;
}

@media (max-width: 480px) {
  .nav {
    justify-content: space-around;
  }
  .home-logo {
    display: none;
  }
}
