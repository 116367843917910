.footer-container {
  width: 100%;
  background-color: #003566;
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: auto;
}

.footer-text,
.logo-links {
  font-weight: 700;
  color: white;
  display: flex;
  justify-content: center;
  gap: 50px;
  align-items: center;
}

.github-link,
.linkedIn-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
  font-family: "Permanent Marker";
}

.footer-logo {
  height: 32px;
}

/* @media (max-width: 1080px) {
  .footer-container {
    display: none;
  }


  
} */

@media (max-width: 480px) {
  .footer-container {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }

  .footer-text,
  .logo-links {
    gap: 10px;
  }

  .footer-logo {
    height: 28px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .footer-container {
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
  }

  .footer-text,
  .logo-links {
    gap: 10px;
  }

  .footer-logo {
    height: 28px;
  }
}
