.About {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  padding-top: 80px;
  height: 70vh;
  gap: 20px;
}

.about-photo-intro {
  font-size: 36px;
}

.about-text-container {
  font-size: 20px;
  max-width: 30%;
  gap: 20px;
  display: flex;
  flex-direction: column;
}

.about-text-container > .learn-more-container {
  margin: 0px;
}

.personal-photo {
  max-height: 480px;
}

@media (max-width: 480px) {
  .About {
    display: flex;
    flex-direction: column;
    padding: 20px;
    padding-top: 65px;
    height: 80vh;
    gap: 0px;
  }

  .about-photo-intro {
    font-size: 23px;
  }

  .about-text-container {
    gap: 10px;
    font-size: 16px;
    max-width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 10px;
  }

  .about-text-container > .learn-more-container {
    margin-top: 0px;
    margin-bottom: 10px;
  }

  .personal-photo {
    height: 130px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .About {
    display: flex;
    padding: 20px;
    height: 60vh;
    gap: 0px;
  }

  .about-photo-intro {
    font-size: 18px;
  }

  .about-text-container {
    font-size: 15px;
    max-width: 90%;
    display: flex;
    flex-direction: column;
  }

  .personal-photo {
    height: 150px;
  }

  .about-text-container > .learn-more-container {
    margin-top: 0px;
  }
}
