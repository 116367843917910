body {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow-y: scroll;
}

.App {
  font-family: "Sofia Sans";
  font-size: 18px;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
