.Projects {
  padding: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: 60px;
  padding-top: 60px;
}

.project-container {
  width: 1350px;
  display: flex;
  height: 45vh;
  box-shadow: 1px 1px 1px 1px #00081417;
}

.project-container.shopping-cart,
.project-container.resume-creator {
  flex-direction: row-reverse;
}

.projects-title {
  font-size: 60px;
  font-weight: 700;
  margin-bottom: 30px;
}

.project-title {
  font-size: 60px;
  font-weight: 700;
}

.project-description {
  font-size: 24px;
}

.project-info {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  margin-left: 25px;
  margin-right: 25px;
  padding: 10px;
}

.project-buttons-container {
  display: flex;
  gap: 20px;
}

.project-live,
.project-code {
  padding: 12px 16px;
  width: 100px;
  border-radius: 50px;
  border: none;
  font-weight: 700;
  font-size: 18px;
}

.project-live:hover,
.project-code:hover {
  opacity: 0.8;
}

.project-image-container {
  display: flex;
  width: 50%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
}

.memory-game {
  background-color: #003566;
  color: white;
}

.memory-game-button {
  background-color: white;
  color: #003566;
}

.project-image-container.memory-game {
  justify-content: flex-end;
  margin-top: auto;
}

.memory-game-image {
  height: 280px;
}

.shopping-cart {
  background-color: #ffc300;
  color: #000814;
}

.shopping-cart-button {
  background-color: #000814;
  color: #ffc300;
}

.shopping-cart-image {
  height: 280px;
}

.portfolio {
  background-color: #001d3d;
  color: white;
}

.portfolio-button {
  background-color: white;
  color: #001d3d;
}

.portfolio-image {
  max-height: 280px;
}

.hyper {
  background-color: white;
  color: #001d3d;
}

.hyper-button {
  background-color: #001d3d;
  color: white;
}

.hyper-image {
  max-height: 290px;
}

.resume-creator {
  background-color: white;
  color: #001d3d;
}

.resume-button {
  background-color: #001d3d;
  color: white;
}

.resume-creator-image {
  max-height: 280px;
}

@media (max-width: 480px) {
  .Projects {
    gap: 40px;
    padding: 0px;
    padding-top: 60px;
    padding-bottom: 20px;
  }

  .project-container {
    width: 95vw;
    height: 60vh;
    display: flex;
    flex-direction: column;
  }

  .project-container.shopping-cart,
  .project-container.resume-creator {
    flex-direction: column-reverse;
  }

  .project-info {
    width: 95%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
  }

  .projects-title {
    font-size: 40px;
    margin-bottom: 10px;
  }

  .project-title {
    font-size: 30px;
    font-weight: 700;
  }

  .project-description {
    font-size: 15px;
  }

  .project-live,
  .project-code {
    padding: 8px 12px;
    width: 100px;
    border-radius: 50px;
    border: none;
    font-weight: 700;
    font-size: 15px;
  }

  .project-image-container {
    display: flex;
    height: 50%;
    width: 95vw;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .project-image-container.memory-game,
  .project-image-container.hyper {
    margin-top: auto;
    justify-content: end;
  }

  .memory-game-image {
    max-height: 220px;
  }

  .shopping-cart-image {
    padding: 5px;
    max-height: 180px;
  }

  .portfolio-image {
    max-height: 190px;
  }

  .hyper-image {
    max-height: 240px;
  }

  .resume-creator-image {
    max-height: 182px;
  }
}

@media (min-width: 481px) and (max-width: 1080px) {
  .Projects {
    gap: 30px;
  }

  .project-container {
    width: 95vw;
    height: 60vh;
    display: flex;
  }
  .project-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-left: 0px;
    margin-right: 0px;
    padding: 10px;
  }

  .projects-title {
    font-size: 30px;
    margin-bottom: -10px;
  }

  .project-title {
    font-size: 30px;
    font-weight: 700;
  }

  .project-description {
    font-size: 15px;
  }

  .project-live,
  .project-code {
    padding: 8px 12px;
    width: 100px;
    border-radius: 50px;
    border: none;
    font-weight: 700;
    font-size: 15px;
  }

  .project-image-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0;
  }

  .project-image-container.memory-game {
    margin-top: auto;
    justify-content: end;
  }

  .memory-game-image {
    max-height: 150px;
  }

  .project-image-container.shopping-cart {
    padding-left: 5px;
  }

  .shopping-cart-image {
    max-height: 210px;
  }

  .portfolio-image {
    max-height: 200px;
  }

  .hyper-image {
    max-height: 225px;
  }

  .resume-creator-image {
    max-height: 110px;
  }
}

@media (min-width: 1981px) {
  .project-image-container.memory-game {
    justify-content: center;
    margin-top: 0px;
  }

  .memory-game-image {
    height: 500px;
    content: url(/home/user/Desktop/Coding/Code/OdinProject/repos/portfolio-project/portfolio/src/assets/images/memoryGamePhoneMock.png);
  }

  .shopping-cart-image {
    max-height: 300px;
  }
}
